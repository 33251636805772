import React, { useEffect, useState } from 'react'

// Chakra imports
import { Button, Flex, FormControl, FormLabel, Input, Text, useColorModeValue } from '@chakra-ui/react'
import DefaultAuth from 'layouts/auth/Default'
import { loginLocal } from 'hooks/authService'
import { newMainUser } from 'hooks/authService'

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white')
  // const textColorSecondary = 'gray.400'
  // const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600')
  // const textColorBrand = useColorModeValue('brand.500', 'white')
  const brandStars = useColorModeValue('brand.500', 'brand.400')
  // const googleBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.200')
  // const googleText = useColorModeValue('navy.700', 'white')
  // const googleHover = useColorModeValue({ bg: 'gray.200' }, { bg: 'whiteAlpha.300' })
  // const googleActive = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.200' })

  const [signInData, setSignInData] = useState({
    userName: '',
    email: '',
    password: '',
    cnpj: '',
    user_data: {
      phone1: '55',
      geo_data: {}
    }
  })

  // const [loginData, setLoginData] = useState({
  //   password: '',
  //   email: signInData.email
  // })

  const [createUserStage, setCreateUserStage] = useState(0)

  useEffect(() => {
    fetch('https://api.ipgeolocation.io/ipgeo?apiKey=3a086497e1ce47a5b4e6aa3f29222e1a')
      .then((response) => response.json())
      .then((data) => {
        const visitorInformation = {
          ip: data.ip,
          isp: data.isp,
          state_code: data.state_code,
          city: data.city,
          zipcode: data.zipcode,
          latitude: data.latitude,
          longitude: data.longitude,
          calling_code: data.calling_code,
          time_zone: data.time_zone.offset,
          current_time: data.time_zone.current_time
        }
        console.log('Dados de geolocalização:', visitorInformation)
        setSignInData((prevFormData) => ({
          ...prevFormData,
          user_data: { ...prevFormData.user_data, geo_data: visitorInformation }
        }))
      })
      .catch((error) => {
        console.error('Error fetching IP:', error)
      })
  }, [])

  useEffect(() => {
    console.log('Form de validação:', createUserStage)
  }, [createUserStage])

  useEffect(() => {
    console.log('signInData:', signInData)
  }, [signInData])

  const handleChange = (event) => {
    const { name, value } = event.target

    setSignInData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const handlePhoneChange = (event) => {
    const { value } = event.target

    setSignInData((prevFormData) => ({
      ...prevFormData,
      user_data: { ...prevFormData.user_data, phone1: value }
    }))
  }

  // const handleChangeLogin = (event) => {
  //   const { name, value } = event.target

  //   setLoginData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value
  //   }))
  // }

  // const logInSubmit = async (event) => {
  //   event.preventDefault()

  //   const { password } = loginData
  //   const { email, user_data, cpf } = signInData

  //   const patchData = { user_data, cpf }

  //   try {
  //     loginLocal(email, password, patchData)

  //     setTimeout(() => {
  //       window.location.href = '/admin/painel'
  //     }, 600)
  //   } catch (error) {
  //     console.error('Error during login:', error)
  //   }
  // }

  const signInSubmit = async (event) => {
    event.preventDefault()

    const { userName, email, password, user_data } = signInData

    try {
      const addNewUser = await newMainUser(userName, email, password, user_data)

      if (addNewUser && addNewUser.status === 201) {
        try {
          loginLocal(email, password)

          setTimeout(() => {
            window.location.href = '/admin/painel'
          }, 600)
        } catch (error) {
          console.error('Error during login:', error)
        }
      }

      console.log('addNewUser: ', addNewUser.status)
    } catch (error) {
      console.error('Erro.', error)
    }
  }

  return (
    <DefaultAuth
    // illustrationBackground={illustration}
    // image={illustration}
    // backgroundColor=""
    // quote={quote}
    >
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '0px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '30px', md: '7vh' }}
        flexDirection="column"
      >
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <FormControl>
            <form onSubmit={signInSubmit}>
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Qual o seu nome?<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                value={signInData.userName}
                name="userName"
                placeholder="Escreva aqui"
                mb="24px"
                fontWeight="500"
                size="lg"
                onChange={handleChange}
                style={{ background: '#ffffff', border: '0px', borderRadius: '100px' }}
              />
              <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
                Seu Telefone?<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                name="phone1"
                value={signInData && signInData.user_data && signInData.user_data.phone1}
                fontSize="sm"
                placeholder="+55 54 99998888"
                mb="24px"
                size="lg"
                type="text"
                variant="auth"
                onChange={handlePhoneChange}
                style={{ background: '#ffffff', border: '0px', borderRadius: '100px' }}
              />
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                O seu email?<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                type="email"
                name="email"
                value={signInData.email}
                placeholder="endereco@email.com"
                mb="24px"
                fontWeight="500"
                size="lg"
                onChange={handleChange}
                style={{ background: '#ffffff', border: '0px', borderRadius: '100px' }}
              />
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Sua senha<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                value={signInData.password}
                name="password"
                placeholder="Digite uma senha forte"
                mb="24px"
                fontWeight="500"
                size="lg"
                onChange={handleChange}
                style={{ background: '#ffffff', border: '0px', borderRadius: '100px' }}
              />
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
                style={{ background: '#ffc100', border: '0px', borderRadius: '100px' }}
              >
                Avançar
              </Button>
            </form>
          </FormControl>
        </Flex>
      </Flex>
    </DefaultAuth>
  )
}

export default SignIn
