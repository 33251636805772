import React from 'react'
import { background, Flex, Link, Text } from '@chakra-ui/react'
import Card from 'components/card/Card'

export default function DealCard({ deal, index, stepsList }) {
  // console.log('StepsList do DealCard', stepsList)
  return (
    <Link href={`/admin/deal/${deal.id}`} key={deal.id}>
      <Flex
        flexDirection={'column'}
        background="white"
        boxShadow="md"
        p="10px 15px"
        borderRadius="10px"
        draggable
        zIndex={1}
        style={{
          opacity: 0,
          animation: 'fadeIn 0.1s ease-in forwards',
          animationDelay: `${index * 0.03}s` // Delay de 0.5s entre cada card
        }}
      >
        {/* <Flex mb="5px" justifyContent={'space-between'}>
          {stepsList &&
            stepsList.length > 0 &&
            stepsList.map((step) => (
              <Flex
                cursor="pointer"
                key={step.id}
                w="10px"
                h="10px"
                background={'#eaeaea'}
                mr="5px"
                borderRadius={'20px'}
                _hover={{ background: '#48BFFF' }}
              />
            ))}
        </Flex> */}
        <style>
          {`
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`}
        </style>

        <Flex flexDirection="column">
          <Text fontSize={'14px'} fontWeight={'800'}>
            {deal.name}
          </Text>
          <Text fontSize={'10px'} fontWeight={'500'}>
            {deal.company.name}
          </Text>
          <Text fontSize={'10px'} fontWeight={'500'}>
            {`${deal.contact.first_name} ${deal.contact.last_name} - ${deal.contact.position}`}
          </Text>
        </Flex>
      </Flex>
    </Link>
  )
}
