import React, { useState, useEffect, useRef } from 'react'
import axios from 'api/axios'
import { Button, Flex, Input, Select, SimpleGrid, Tag, Text, Textarea } from '@chakra-ui/react'
import { FaPhone, FaTasks, FaEnvelope, FaComments, FaHandshake, FaWalking, FaComment } from 'react-icons/fa'
import { Divider } from 'primereact/divider'
import { Calendar } from 'primereact/calendar'
import { SelectButton } from 'primereact/selectbutton'
import authHeader from 'hooks/authHeader'
import { getUserAccountId } from 'hooks/authService'
import { OverlayPanel } from 'primereact/overlaypanel'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' //

export default function Activities({ activity, refreshComponent }) {
  const [newData, setNewData] = useState({
    // account_id: null,
    // owner_id: null,
    // entity: '',
    // entity_id: null,
    // name: '',
    description: activity.description,
    // type: '',
    status: activity.status,
    coments: activity.coments
    // dead_line: activity.dead_line
  })

  const [showNewComment, setShowNewComment] = useState(false)
  const quillRef = useRef(null)
  const [editorContent, setEditorContent] = useState(activity.coments.comment)

  const op = useRef(null)

  function RenderActivityIcon({ value }) {
    const activity = activityMapping[value]

    if (!activity) {
      return <span>Ops..</span>
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
        <span style={{ marginRight: '8px', fontSize: '14px' }}>{activity.icon}</span>
        <span style={{ fontSize: '0.85rem' }}>{activity.name}</span>
      </div>
    )
  }

  // function convertTimestampToDateAndTime(timestamp) {
  //   const date = new Date(timestamp * 1000)

  //   // Obtém a data e hora sem ajustar para o fuso horário local
  //   const year = date.getUTCFullYear()
  //   const month = (date.getUTCMonth() + 1).toString().padStart(2, '0') // Os meses vão de 0 a 11, por isso +1
  //   const day = date.getUTCDate().toString().padStart(2, '0')
  //   const hours = date.getUTCHours().toString().padStart(2, '0')
  //   const minutes = date.getUTCMinutes().toString().padStart(2, '0')
  //   const seconds = date.getUTCSeconds().toString().padStart(2, '0')

  //   // Retorna no formato "DD/MM/YYYY HH:MM:SS"
  //   return `${day}/${month}/${year} ${hours}h${minutes}`
  // }

  function convertTimestampToDateAndTime(timestamp) {
    // Remove os três últimos dígitos do timestamp
    const date = new Date(Math.floor(timestamp / 1000) * 1000)
    const day = date.getDate().toString().padStart(2, '0')
    const month =
      date.toLocaleString('default', { month: 'short' }).charAt(0).toUpperCase() +
      date.toLocaleString('default', { month: 'short' }).slice(1)
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    return `${day}/${month} • ${hours}h${minutes}`
  }

  function formatDate(isoDate) {
    const date = new Date(isoDate)

    const day = date.getDate()
    const month = date.toLocaleString('default', { month: 'short' }) // Formato curto do mês
    const hour = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')

    return `${day}/${month.charAt(0).toUpperCase() + month.slice(1)} • ${hour}h${minutes}`
  }

  const activityMapping = {
    call: { icon: <FaPhone />, name: 'Ligação' },
    task: { icon: <FaTasks />, name: 'Tarefa' },
    email: { icon: <FaEnvelope />, name: 'Email' },
    message: { icon: <FaComments />, name: 'Mensagem' },
    meeting: { icon: <FaHandshake />, name: 'Reunião' },
    visit: { icon: <FaWalking />, name: 'Visita' }
  }

  const [status, setStatus] = useState(activity.status)

  async function updateActivity(data) {
    try {
      const response = await axios.patch(`/activities/${activity.id}`, data, { headers: authHeader() })
      console.log('Atividade atualizada!', response)
      refreshComponent()
    } catch (error) {
      console.log('Não foi possível atualizar a atividade.', error)
    }
  }

  function handleChange(event) {
    const { name, value } = event.target
    setNewData((prevState) => ({
      ...prevState,
      [name]: value
    }))
    setStatus(value)
    updateActivity({ [name]: value })
  }

  useEffect(() => {
    console.log('newData activity: ', newData)
  }, [newData])

  useEffect(() => {
    console.log('timeline activity: ', activity)
  }, [activity])

  function submitComment(event) {
    const { name, value } = event.target
    setNewData((prevState) => ({
      ...prevState,
      coments: {
        ...prevState.coments,
        now: Math.floor(Date.now() / 1000),
        comment: editorContent
      }
    }))
    setStatus(value)
    updateActivity({
      coments: {
        now: Math.floor(Date.now() / 1000),
        comment: editorContent
      }
    })
    setShowNewComment(false)
  }

  return (
    <Flex p="10px" borderRadius="20px" w="100%" flexDirection="column">
      <Flex
        flexDirection="row"
        mb="10px"
        justifyContent="space-between"
        alignItems="center"
        style={{ borderBottom: '1px solid #ccc' }}
      >
        <RenderActivityIcon value={activity.type} />
        <Text mr="10px" fontSize="0.8rem" width={'fit-content'}>
          {activity.start_date && convertTimestampToDateAndTime(activity.start_date)}
        </Text>
        <Text mr="10px" fontSize="0.8rem" width="105px" color={'gray.400'}>
          {activity.updated_at && formatDate(activity.updated_at)}
        </Text>
        <Flex pb="5px" width={'fit-content'}>
          <SelectButton
            name="status"
            value={newData.status}
            onChange={handleChange}
            options={[
              { value: 'todo', name: 'Pendente' },
              { value: 'doing', name: 'Agendado' }, // alterar para scheduled
              // { value: 'blocked', name: 'Impedida' },
              // { value: 'postponed', name: 'Adiada' },
              { value: 'canceled', name: 'Cancelada' },
              { value: 'done', name: 'Realizada' }
            ]}
            optionLabel="name"
            optionValue="value"
            style={{
              fontSize: '12px',
              width: '100%',
              margin: '0px'
            }}
            pt={{
              button: {
                style: { fontSize: '12px', fontWeight: '500', padding: '5px 10px', borderRadius: '20px' }
              },
              label: { style: { fontSize: '12px', fontWeight: '500' } }
            }}
          />
        </Flex>
      </Flex>
      <Flex alignContent="center">
        <Text fontSize={'14px'} mr="5px">
          {activity.description}
        </Text>
        {activity.activity_data.emailData?.subject && (
          <Tag
            background={'#fafafa'}
            p={'3px 15px'}
            fontSize={'14px'}
            onClick={(e) => op.current.toggle(e)}
            cursor={'pointer'}
            width={'fit-content'}
            borderRadius={'20px'}
          >
            {activity.activity_data.emailData.subject}...
          </Tag>
        )}
      </Flex>
      {activity.activity_data?.emailData?.subject && (
        <>
          <OverlayPanel ref={op} style={{ maxWidth: '600px', padding: '15px', borderRadius: '20px' }}>
            <Flex flexDirection={'column'}>
              <Text fontSize={'14px'}>{`Para: ${activity.activity_data.emailData.to}`}</Text>
              <Text
                fontSize={'14px'}
                pb="10px"
                my="5px"
                borderBottom={'1px solid #ccc'}
              >{`Assunto: ${activity.activity_data.emailData.subject}`}</Text>
              <Text
                pt="10px"
                dangerouslySetInnerHTML={{ __html: activity.activity_data?.emailData?.message }}
              />
            </Flex>
          </OverlayPanel>
        </>
      )}
      <Flex mt="5px" width={'100%'}>
        {!showNewComment && (
          <Flex
            onClick={() => setShowNewComment(true)}
            background={'#fafafa'}
            padding={'10px'}
            borderRadius={'20px'}
            cursor={'text'}
            width={'100%'}
          >
            <FaComment color="#8f9bba" className="mr-3" size={'14px'} />
            {newData?.coments?.comment && (
              <Text
                dangerouslySetInnerHTML={{ __html: newData?.coments?.comment }}
                isTruncated
                noOfLines={1}
                maxW="100%"
              ></Text>
            )}
          </Flex>
        )}
        {showNewComment && (
          <Flex flexDirection={'column'} width={'100%'} background={'#fafafa'} p="10px" borderRadius={'20px'}>
            <ReactQuill
              ref={quillRef}
              value={editorContent}
              onChange={setEditorContent}
              theme="snow"
              modules={{
                toolbar: [
                  // [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  // [{ size: ['small', false, 'large', 'huge'] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'color'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  // [{ align: [] }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  // [{ color: [] }, { background: [] }],
                  // [{ font: [] }],
                  ['link', 'image'],
                  ['clean'] // Adicione mais opções da barra de ferramentas conforme necessário
                ]
              }}
            />
            <Flex gap="10px" flexDirection={'row-reverse'}>
              <Button
                bg="#49BFFF"
                color="white"
                colorScheme="blue"
                borderRadius="20px"
                fontSize={'14px'}
                onClick={submitComment}
                width={'fit-content'}
              >
                Salvar
              </Button>
              <Button
                variant="outline"
                color="#49BFFF"
                colorScheme="blue"
                borderRadius="20px"
                fontSize={'14px'}
                onClick={() => setShowNewComment(false)}
                width={'fit-content'}
              >
                Cancelar
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>

      {/* <Textarea placeholder="Comentário" /> */}

      {/* <Button bg="#49BFFF" color="white" colorScheme="blue" borderRadius="20px" fontSize={'14px'}>
                Salvar
              </Button> */}
    </Flex>
  )
}
