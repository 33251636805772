import React, { useState, useEffect } from 'react'
import axios from 'api/axios'
import { app } from '../../../api/feathers'

import { Box, SimpleGrid } from '@chakra-ui/react'

import RegistroLigacoes from './components/registroLigacoes'
import authHeader from 'hooks/authHeader'
import { getUserAccountId } from 'hooks/authService'

export default function CallsList({ type }) {
  const account_id = getUserAccountId()
  const [vonoSecrets, setVonoSecrets] = useState()

  const [callsList, setCallsList] = useState([])

  useEffect(() => {
    getAccountSettings()
  }, [])

  useEffect(() => {
    getVonoRecords()
  }, [vonoSecrets])

  // useEffect(() => {
  //   // console.log('callsList', callsList)
  // }, [vonoSecrets, callsList])

  async function getAccountSettings() {
    try {
      const response = await axios.get(`/accounts/${account_id}`, { headers: authHeader() })
      // console.log('response.data', response.data)

      const { vono_records } = response.data.settings

      setVonoSecrets(vono_records)
    } catch (error) {
      console.error('Erro ao obter configurações:', error)
    }
  }

  async function getVonoRecords() {
    if (!vonoSecrets) return

    const request = await axios.get(
      `https://vono.me/api/recording/${vonoSecrets.vono_token}/${vonoSecrets.vono_key}?date_ini=2024-01-01&date_end=2024-12-31&limit=10000`
    )
    setCallsList(request.data.data)
    // console.log('request.data', request.data.data)
    return request
  }

  return (
    <>
      <Box mt={type ? '0px' : '80px'}>
        <RegistroLigacoes
          columnsData={[
            {
              Header: 'Audio',
              accessor: 'download'
            },
            {
              Header: 'Data',
              accessor: 'calldate'
            },

            {
              Header: 'SDR',
              accessor: 'source'
            },
            {
              Header: 'Duração',
              accessor: 'duration2'
            },
            {
              Header: 'Número',
              accessor: 'destination'
            }
          ]}
          tableData={callsList}
          vonoToken={vonoSecrets && vonoSecrets.vono_token}
          vonoKey={vonoSecrets && vonoSecrets.vono_key}
          resultsPerPage="50"
        />
      </Box>
    </>
  )
}
