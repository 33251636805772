/* eslint-disable */
import { Button, Flex, Link, Tag, Text } from '@chakra-ui/react'
import axios from 'api/axios'
import { FaEnvelope, FaPhone, FaSearch } from 'react-icons/fa'
import { MdPersonAdd } from 'react-icons/md'
import { getUserAuthenticationRole } from 'hooks/authService'
import pipelineStatus from 'components/setPipelineStatusName'

// import Menu from 'components/menu/MainMenu'
import React, { useState, useEffect } from 'react'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { InputText } from 'primereact/inputtext'
import { getUserAccountId } from 'hooks/authService'
import authHeader from 'hooks/authHeader'

export default function AccountUsers(props) {
  const [accountUsersList, setAccountUsersList] = useState([])
  const [status, setStatus] = useState('')
  const userRole = getUserAuthenticationRole()

  const accountId = getUserAccountId()
  // console.log('accountId', accountId)

  useEffect(() => {
    getAccountUsers()
  }, [])

  useEffect(() => {
    console.log('accountUsersList:', accountUsersList)
  }, [accountUsersList])

  async function getAccountUsers() {
    if (!accountId) return

    try {
      const { data } = await axios.get('/users', {
        headers: authHeader(),
        query: {
          user_data: {
            main_account_id: accountId
          }
        }
      })

      console.log('datas:', data.data)
      setAccountUsersList(data.data)
    } catch (error) {
      console.error('Erro ao buscar usuarios:', error)
    }
  }

  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    first_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'address.municipio': { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'address.uf': { value: null, matchMode: FilterMatchMode.CONTAINS }
  })

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = { ...filters }

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const renderHeader = () => {
    return (
      <Flex justifyContent={'space-between'} gap={'10px'}>
        <Text fontSize={'24px'} fontWeight={'800'} color={'#1b2559'}>
          Usuários
        </Text>
        <Flex alignItems={'center'} background={'#f4f7fe'} borderRadius={'20px'}>
          <div className="p-inputgroup flex-1">
            <span
              className="p-inputgroup-addon"
              style={{
                background: 'transparent'
              }}
            >
              <FaSearch />
            </span>
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Buscar"
              v-model="value1"
              style={{
                padding: ' 0px 10px',
                width: '100%',
                background: 'transparent',
                fontSize: '14px'
              }}
              className="outline-none"
            />
          </div>
        </Flex>
        <Flex gap={'10px'} alignItems={'center'}>
          {userRole === 'main' && (
            <Link href="/admin/new-user">
              <Button
                leftIcon={<MdPersonAdd fontWeight={'500'} size={'18px'} />}
                colorScheme="gray"
                color="gray.400"
                variant="solid"
                fontWeight={'500'}
                borderRadius={'50px'}
                size="md"
              >
                Novo
              </Button>
            </Link>
          )}
        </Flex>
      </Flex>
    )
  }

  const header = renderHeader()

  const company = (item) => {
    return (
      // <Link href={`/admin/company/${item.id}`}>
      <Flex flexDirection={'column'} color="#1b2559">
        <Flex>
          <Text fontSize={'14px'} fontWeight={'600'}>
            {`${item.first_name} ${item.last_name && item.last_name}`}
          </Text>
        </Flex>
      </Flex>
      // </Link>
    )
  }

  const contactData = (item) => {
    return (
      <Flex gap={'5px'} wrap={'wrap'}>
        <Link href={`tel:${item.user_data.phone1.replace(/\D/g, '')}`}>
          <Tag colorScheme="whatsapp" size="sm" borderRadius={'20px'} pr="12px" py="3px">
            <FaPhone size={'10px'} style={{ marginRight: '5px' }} />
            {item.user_data.phone1}
          </Tag>
        </Link>

        <Tag colorScheme="gray" size="sm" borderRadius={'20px'} pr="12px" py="5px">
          <FaEnvelope size={'12px'} style={{ marginRight: '5px' }} />
          {item.email}
        </Tag>

        {/* {item.contact_data.linkedin && (
          <Tag colorScheme="linkedin" size="sm" borderRadius={'20px'} pr="12px" py="5px">
            <FaLinkedin size={'12px'} style={{ marginRight: '5px' }} />
            {item.contact_data.linkedin}
          </Tag>
        )}
        {item.contact_data.instagram && (
          <Tag colorScheme="pink" size="sm" borderRadius={'20px'} pr="12px" py="5px">
            <FaInstagram size={'12px'} style={{ marginRight: '5px' }} />
            {item.contact_data.instagram}
          </Tag>
        )}
        {item.contact_data.x && (
          <Tag colorScheme="twitter" size="sm" borderRadius={'20px'} pr="12px" py="5px">
            <FaTwitter size={'12px'} style={{ marginRight: '5px' }} />
            {item.contact_data.x}
          </Tag>
        )} */}
      </Flex>
    )
  }

  const profile = (item) => {
    return (
      <Flex color="#1b2559">
        <Text fontSize={'14px'} fontWeight={'600'}>
          {item.role}
        </Text>
      </Flex>
    )
  }

  const address = (item) => {
    return (
      <Flex color="#1b2559">
        <Text fontSize={'14px'} fontWeight={'600'}>
          {item.user_data.geo_data.city}
        </Text>
      </Flex>
    )
  }

  const pipeline = (item) => {
    const { status, color } = pipelineStatus(item.pipeline)
    return (
      <Flex color="#1b2559">
        <Tag colorScheme={color} size="sm" borderRadius={'20px'} fontSize={'12px'} fontWeight={'600'}>
          {item.status}
        </Tag>
      </Flex>
    )
  }

  return (
    <Flex gap={'20px'} flexDirection={'column'} mt="80px">
      <Flex bg={'white'} p={'20px'} borderRadius={'20px'} width={'fit-content'}>
        <DataTable
          value={accountUsersList && accountUsersList}
          header={header}
          filters={filters}
          paginator
          rows={8}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey="id"
          globalFilterFields={['name', 'contact', 'address', 'address.municipio', 'address.uf']}
          tableStyle={{ width: '100vh' }}
          pt={{
            header: {
              style: {
                background: '#fff'
              }
            }
          }}
        >
          <Column
            field="name"
            header="Usuário"
            body={company}
            style={{ minWidth: '250px' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0'
                }
              }
            }}
          ></Column>
          <Column
            field="contact"
            header="Perfil de Usuário"
            body={profile}
            style={{ minWidth: '200px' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0'
                }
              }
            }}
          ></Column>
          <Column
            field="contact"
            header="Dados de Contato"
            body={contactData}
            style={{ minWidth: '350px' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0'
                }
              }
            }}
          ></Column>
          <Column
            field="address"
            header="Localidade"
            body={address}
            style={{ minWidth: '200px' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0'
                }
              }
            }}
          ></Column>
          <Column
            field="pipeline"
            header="Status"
            body={pipeline}
            style={{ minWidth: '150px' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0'
                }
              }
            }}
          ></Column>
        </DataTable>
      </Flex>
    </Flex>
  )
}
