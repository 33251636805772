import { Flex } from '@chakra-ui/react'
import AccountUsersTable from './components/usersTable'
import { getUserAccountId } from 'hooks/authService'
import React, { useEffect } from 'react'

export function Users() {
  return (
    <Flex mt={'80px'}>
      <AccountUsersTable />
    </Flex>
  )
}
