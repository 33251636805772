import { Button, Flex, Icon, Link, Text, useColorModeValue } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { MdBarChart, MdContactSupport, MdWhatsapp } from 'react-icons/md'

export default function Suport({ accountData }) {
  const textColor = useColorModeValue('navy.700', 'white')
  const apiKey = process.env.REACT_APP_EVOLUTION_API_KEY
  const server = process.env.REACT_APP_EVOLUTION_BASEURL

  const handleSendMessage = async () => {
    const whatsappMessageToken = {
      number: '551151283600',
      text: `Teste de envio de mensagem`
      // options: {
      //   delay: 0,
      //   presence: 'composing',
      //   linkPreview: false
      // },
      // textMessage: {
      // }
    }

    try {
      const response = await fetch(
        `${server}/message/sendText/${process.env.REACT_APP_OTTENSALES_INSTANCE}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            apikey: apiKey
          },
          body: JSON.stringify(whatsappMessageToken)
        }
      )
      if (response.status === 200) {
        alert('Mensagem enviada com sucesso!')
      } else {
        alert('Falha ao enviar a mensagem.')
      }
    } catch (error) {
      console.error('Erro ao enviar a mensagem:', error)
      alert('Erro ao enviar a mensagem.')
    }
  }

  return (
    <Card h="fit-content">
      <Flex alignItems="center" w="100%" mb="30px">
        <Icon as={MdContactSupport} width="20px" height="20px" mr={3} color="inherit" />

        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Suporte SalesBase
        </Text>
      </Flex>
      <Link href={`https://wa.me/551151283600?text=Quero falar com suporte OttenSales.`} target="_blank">
        <Flex
          background="#49bfff"
          alignItems={'center'}
          borderRadius="50px"
          padding="10px 20px"
          _hover={{
            background: '#3a9dd6'
          }}
        >
          <Icon as={MdWhatsapp} color="white" width="20px" height="20px" mr="5px" />
          <Text color={'white'}>Falar com o suporte</Text>
        </Flex>
      </Link>
    </Card>
  )
}
