import React, { useState, useEffect } from 'react'
import { Flex, useColorModeValue, Text } from '@chakra-ui/react'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { getUserAccountId } from 'hooks/authService'

import DailyTasks from './dailyTasks'

export default function CompanyForm() {
  const textColor = useColorModeValue('navy.700', 'white')
  const account_id = getUserAccountId()

  return (
    <Flex flexDirection={'column'} gap="20px">
      <DailyTasks />
      {/* <NextTasks /> */}
      {/* <DailyTasksGoal /> // Lista de objetivos das tarefas to dia */}
      {/* <DailyInsights /> //Dicas para o seller cumprir as metas do dia */}
    </Flex>
  )
}
