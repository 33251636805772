import React, { useState, useEffect } from 'react'
import axios from 'api/axios'
import { Button, Flex, Input, Select, SimpleGrid, Tag, Text, Textarea } from '@chakra-ui/react'
import { FaPhone, FaTasks, FaEnvelope, FaComments, FaHandshake, FaWalking } from 'react-icons/fa'
import { Divider } from 'primereact/divider'
import { Calendar } from 'primereact/calendar'
import { SelectButton } from 'primereact/selectbutton'
import authHeader from 'hooks/authHeader'
import { getUserAccountId } from 'hooks/authService'
import Activity from './activity'
import ActivityTimeLine from './activityTimeline'

export default function FormActivities(props) {
  const { entity, entityId, refreshComponent } = props
  const [activityList, setActivityList] = useState({})
  const [templatesList, setTemplatesList] = useState({})
  const account_id = getUserAccountId()

  useEffect(() => {
    getActivityList(entity, entityId)
    getAccountTemplates()
  }, [refreshComponent, entity, entityId])

  useEffect(() => {
    console.log('activityList', activityList)
  }, [activityList])

  async function getActivityList() {
    if (!account_id) return
    try {
      const response = await axios.get(
        `/activities?account_id=${account_id}&entity=${entity}&entity_id=${entityId}`,
        { headers: authHeader() }
      )
      const sortedActivities = sortActivitiesByDeadline(response.data.data)
      setActivityList(sortedActivities)
      console.log('activity response: ', sortedActivities)
    } catch (error) {
      console.log('error', error)
    }
    return
  }

  async function getAccountTemplates() {
    if (!account_id) return
    try {
      const response = await axios.get(`/templates?account_id=${account_id}`, { headers: authHeader() })
      setTemplatesList(response.data.data)
      console.log('Templates response: ', response.data.data)
    } catch (error) {
      console.log('error', error)
    }
    return
  }

  const sortActivitiesByDeadline = (data) => {
    const sortedData = data.sort((a, b) => {
      return parseInt(a.start_date) - parseInt(b.start_date)
    })
    return sortedData
  }

  return (
    <Flex flexDirection="column" gap={2} mt="20px">
      {activityList.length > 0 && (
        <>
          {/* <Flex
            bg="white"
            borderRadius="20px 20px 0 0 "
            p="13px 25px"
            w="fit-content"
            mt="20px"
            boxShadow="lg"
          ></Flex> */}
          <Flex
            flexDirection={'column'}
            bg={'white'}
            borderRadius={'20px 20px 20px 20px'}
            p="20px"
            mt="0px"
            boxShadow="lg"
          >
            <Text fontSize="1.15rem" fontWeight="800" mb="10px">
              Atividades pendentes
            </Text>
            {activityList.map(
              (activity) =>
                activity.status === 'todo' && (
                  <Activity
                    key={activity.id}
                    activity={activity}
                    refreshComponent={getActivityList}
                    templatesList={templatesList}
                  />
                )
            )}
            {activityList.map(
              (activity) =>
                activity.status === 'doing' && (
                  <Activity
                    key={activity.id}
                    activity={activity}
                    refreshComponent={getActivityList}
                    templatesList={templatesList}
                  />
                )
            )}
          </Flex>
        </>
      )}
      {activityList.length > 0 && (
        <>
          {/* <Flex
            bg="white"
            borderRadius="20px 20px 0 0 "
            p="13px 25px"
            w="fit-content"
            mt="20px"
            boxShadow="lg"
          >
            <Text fontSize="1.15rem" fontWeight="800">
              Registro de Atividades
            </Text>
          </Flex> */}
          <Flex
            flexDirection={'column'}
            bg={'white'}
            borderRadius={'20px 20px 20px 20px'}
            p="20px"
            mt="10px"
            boxShadow="lg"
          >
            <Text fontSize="1.15rem" fontWeight="800" mb="10px">
              Registro de Atividades
            </Text>
            {activityList.map(
              (activity) =>
                activity.status !== 'todo' &&
                activity.status !== 'doing' && (
                  <ActivityTimeLine
                    key={activity.id}
                    activity={activity}
                    refreshComponent={getActivityList}
                  />
                )
            )}
          </Flex>
        </>
      )}
      {activityList.length === 0 && (
        <Flex bg="whiteAlpha.700" p="20px" borderRadius="50px" w="100%" justifyContent="center">
          Sem atividades
        </Flex>
      )}
    </Flex>
  )
}
