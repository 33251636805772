import { Divider, Flex, Icon, Text } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { useEffect, useState } from 'react'
import { Calendar } from 'react-calendar'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

export default function MiniCalendar(props) {
  const { selectRange, activities, accountData, ...rest } = props
  const [value, onChange] = useState(new Date())
  const [newCompanies, setNewCompanies] = useState('')

  console.log('activities:', activities)
  console.log('accountData:', accountData)
  console.log('value:', value)

  useEffect(() => {
    calculateProspectNumbers(accountData)
  }, [accountData])

  function getTodayEndTimestamp() {
    const now = new Date()
    now.setHours(23, 59, 59, 999) // Define o horário para 23:59:59.999
    return now.getTime() // Retorna o timestamp
  }

  function calculateProspectNumbers(accountData) {
    if (!accountData) return

    const { ticket, conversionRate, monthlyGoal } = accountData.business_data

    const rate = conversionRate / 100
    const companiesToAdd = Math.ceil(monthlyGoal / ticket / rate / 20)

    return setNewCompanies(companiesToAdd)
  }
  function countActivitiesType(activities) {
    if (!activities) return { meeting: 0, call: 0, email: 0, task: 0 } // Retorna um objeto vazio se a lista estiver vazia ou indefinida

    const activityCounts = { meeting: 0, call: 0, email: 0, task: 0 }
    const todaysActivitiesTimeStamp = getTodayEndTimestamp()
    console.log('todaysActivitiesTimeStamp', todaysActivitiesTimeStamp)

    activities.forEach((activity) => {
      if (activityCounts.hasOwnProperty(activity.type) && activity.start_date <= todaysActivitiesTimeStamp) {
        activityCounts[activity.type]++
        console.log('activity startDate:', activity.start_date)
      }
    })

    return activityCounts // Retorna o objeto com os contadores
  }

  const { meeting, call, email, task } = countActivitiesType(activities)

  return (
    <Flex
      // align="center"
      background={'white'}
      borderRadius={'20px'}
      direction="column"
      w="100%"
      maxW="max-content"
      p="20px 15px"
      h="fit-content"
      // {...rest}
    >
      <Calendar
        onChange={onChange}
        value={value}
        selectRange={selectRange}
        view={'month'}
        tileContent={<Text color="brand.500"></Text>}
        prevLabel={<Icon as={MdChevronLeft} w="24px" h="24px" mt="4px" />}
        nextLabel={<Icon as={MdChevronRight} w="24px" h="24px" mt="4px" />}
      />
      <Divider mt="10px" />
      <Flex mt="10px" p={'10px 10px 0 10px'}>
        <Text fontWeight={'600'} mr="5px">
          Atividades para hoje:
        </Text>
        <Text fontWeight={'600'}>40/{call + meeting + email + task + newCompanies}</Text>
      </Flex>
      <Flex p={'10px 10px 0 10px'} justifyContent={'space-between'}>
        <Text mr="5px">Adicionar negócios:</Text>
        <Text fontWeight={'600'}>{newCompanies}</Text>
      </Flex>
      <Flex p={'10px 10px 0 10px'} justifyContent={'space-between'}>
        <Text mr="5px">Ligações agendadas:</Text>
        <Text fontWeight={'600'}>{call}</Text>
      </Flex>
      <Flex p={'10px 10px 0 10px'} justifyContent={'space-between'}>
        <Text mr="5px">Emails agendados:</Text>
        <Text fontWeight={'600'}>{email}</Text>
      </Flex>
      <Flex p={'10px 10px 0 10px'} justifyContent={'space-between'}>
        <Text mr="5px">Tarefas agendadas:</Text>
        <Text fontWeight={'600'}>{task}</Text>
      </Flex>
      <Flex p={'10px 10px 0 10px'} justifyContent={'space-between'}>
        <Text mr="5px">Reuniões agendadas:</Text>
        <Text fontWeight={'600'}>{meeting}</Text>
      </Flex>
    </Flex>
  )
}
