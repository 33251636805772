// Chakra imports
import { Box, SimpleGrid, Flex } from '@chakra-ui/react'

// Custom components
import React, { useState, useEffect } from 'react'
import { getUserAuthentication } from 'hooks/authService'
import authHeader from 'hooks/authHeader'
import axios from 'api/axios'
import Action from './components/action'

export default function SellerActionDash() {
  const userData = getUserAuthentication()

  let userRole, main_account_id

  if (userData) {
    userRole = userData?.user?.role
    main_account_id = userData?.user?.user_data?.main_account_id
  }

  const [accountData, setAccountData] = useState({})

  useEffect(() => {
    async function getUserAccount() {
      try {
        const response = await axios.get(`/accounts/${main_account_id}`, { headers: authHeader() })
        setAccountData(response.data)
      } catch (error) {
        console.error('Crie uma nova conta.', error)
      }
    }
    getUserAccount()
  }, [])

  useEffect(() => {
    console.log('Conta autenticada:', accountData)
  }, [accountData])

  return (
    <Box pt={{ base: '70px', md: '70px', xl: '70px' }}>
      <SimpleGrid rows="2">
        <Flex flexDirection={'row'}>
          <Action />
        </Flex>
      </SimpleGrid>
    </Box>
  )
}
