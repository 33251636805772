// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Text,
  Select,
  useColorModeValue,
  SimpleGrid,
  Skeleton,
  Spinner
} from '@chakra-ui/react'
// Custom components
import AnimalsAgeDonutCard from './DonutCardAnimalsAge'
import StatusDonutChartCard from './DonutCardStatus'
import DonutChartCardSpecies from './DonutCardSpecies'
import React, { useState, useEffect } from 'react'
import { MdDone } from 'react-icons/md'
import useGetAccountCompanies from 'hooks/getters/useGetAccountCompanies'
import useGetAccountDeals from 'hooks/getters/useGetAccountDeals'
import { getUserAccountId } from 'hooks/authService'
import CompanyContacts from './../../formClient/components/companyContacts'
import PipelineDash from './PIpelineDash'

export default function ClientsDash(props) {
  const { height, weight, labels, ...rest } = props

  const { companyList } = useGetAccountCompanies()
  const { dealsList } = useGetAccountDeals()

  // console.log('companyList:', companyList)

  const [clients, setClients] = useState()

  function getClientsQty(companyList) {
    // Verifica se a lista existe e não está vazia
    if (!companyList) return 0

    let clients = 0

    companyList.forEach((company) => {
      if (company.pipeline === 'client') {
        clients++
      }
    })

    return setClients(clients)
  }

  useEffect(() => {
    getClientsQty(companyList)
  }, [companyList])

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const cardColor = useColorModeValue('white', 'navy.700')
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset')
  return (
    <Flex borderRadius="20px" px="20px" bg="white" p="20px" flexDirection={'column'}>
      <Flex flexDirection="row" justifyContent={'space-between'}>
        <Flex alignItems="center" w="100%">
          <Icon as={MdDone} width="20px" height="20px" mr={3} color="inherit" />

          <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
            Dashboard
          </Text>
        </Flex>
        <Flex gap={'10px'}>
          <Flex
            flexDirection="column"
            alignContent="center"
            boxShadow={cardShadow}
            p="10px 20px"
            borderRadius="20px"
          >
            <Text color="#A3AED0" fontSize="sm" fontWeight="700" mb="-7px">
              Empresas
            </Text>
            <Text color="#1b2559" fontSize="32px" fontWeight="700">
              {companyList.length ? companyList.length : <Spinner size="xs" />}
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            alignContent="center"
            boxShadow={cardShadow}
            p="10px 20px"
            borderRadius="20px"
          >
            <Text color="#A3AED0" fontSize="sm" fontWeight="700" mb="-7px">
              Negócios
            </Text>
            <Text color="#1b2559" fontSize="32px" fontWeight="700">
              {dealsList.length ? dealsList.length : <Spinner size="xs" />}
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            alignContent="center"
            boxShadow={cardShadow}
            p="10px 20px"
            borderRadius="20px"
          >
            <Text color="#A3AED0" fontSize="sm" fontWeight="700" mb="-7px">
              Clientes
            </Text>
            <Text color="#1b2559" fontSize="32px" fontWeight="700">
              {clients}
            </Text>
          </Flex>
        </Flex>
        {/* <SimpleGrid minChildWidth="130px" columns={2} justifyItems={'center'} alignItems={'center'} py="20px"> */}
        {/* <StatusDonutChartCard
          data={[companyList && companyList.length, dealsList && dealsList.length, clients]}
          weight={'100%'}
          height={'80%'}
          labels={false}
        /> */}

        {/* </SimpleGrid> */}
      </Flex>
      <Flex>
        <PipelineDash />
      </Flex>
    </Flex>
  )
}
