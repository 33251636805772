// // Chakra imports
// import {
//   Avatar,
//   Box,
//   Icon,
//   Link,
//   Select,
//   SimpleGrid,
//   Text,
//   background,
//   useColorModeValue,
//   Button,
//   Checkbox,
//   Flex,
//   FormControl,
//   FormLabel,
//   Radio,
//   RadioGroup,
//   Stack,
//   Input,
//   InputGroup,
//   InputRightElement,
//   Textarea
// } from '@chakra-ui/react'

// // Custom components
// import React, { useState, useEffect } from 'react'
// import { MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy, MdPeople, MdVerifiedUser } from 'react-icons/md'
// import { getUserAuthentication } from 'hooks/authService'
// import authHeader from 'hooks/authHeader'
// import axios from 'api/axios'
// import Pipeline from './components/pipeline'
// import { useLocation } from 'react-router-dom'

// export default function AdminDefault() {
//   const textColor = useColorModeValue('navy.700', 'white')
//   const userData = getUserAuthentication()

//   const [pipelineList, setPipelineList] = useState({
//     id: null
//   })

//   const {
//     user: {
//       user_data: { main_account_id: accountId },
//       role: userRole // para permissão de renomeação e reordenação dos steps de cada pipeline <InputEdit>
//     }
//   } = userData

//   async function getAccountPipelines() {
//     try {
//       const response = await axios.get(`/pipelines?account_id=${accountId}&$sort[order]=1`, {
//         headers: authHeader()
//       })
//       setPipelineList(response.data.data)
//     } catch (error) {
//       console.error('PipelineId Not Found.', error)
//     }
//   }

//   function getOrderedPipelines(pipelines) {
//     if (pipelines.length === 1) {
//       return pipelines
//     }
//     return pipelines.steps.sort((a, b) => a.order - b.order)
//   }

//   useEffect(() => {
//     if (accountId) getAccountPipelines()
//   }, [])

//   return (
//     <Box pt={{ base: '50px', md: '80px', xl: '80px' }}>
//       <Flex flexDirection={'row'} h={'fit-content'}>
//         {pipelineList && <Pipeline accountPipelines={pipelineList} onUpdate={getAccountPipelines} />}
//       </Flex>
//     </Box>
//   )
// }

// Chakra imports
import { Box, Flex } from '@chakra-ui/react'

// Custom components
import React from 'react'

import Pipeline from './components/pipeline'

export default function PipelinePage() {
  return (
    <Box pt={{ base: '50px', md: '80px', xl: '80px' }}>
      <Flex flexDirection={'row'} h={'fit-content'}>
        <Pipeline />
      </Flex>
    </Box>
  )
}
