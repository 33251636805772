import React, { useState, useEffect, useRef } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Input,
  Textarea,
  SimpleGrid,
  useColorModeValue,
  Text,
  IconButton
} from '@chakra-ui/react'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import { getUserAccountId } from 'hooks/authService'

export default function ScriptForm(props) {
  const textColor = useColorModeValue('navy.700', 'white')
  const accountId = getUserAccountId()
  const { pipeline_id: pipelineIdFromParams } = useParams() || ''
  const [pipeline_id, setPipelineId] = useState(pipelineIdFromParams)
  // const [newPipelineOrder, setNewPipelineOrder] = useState(1)

  const [pipelineSteps, setPipelineSteps] = useState([])

  const [formData, setFormData] = useState({
    name: '',
    account_id: accountId,
    order: 0
  })

  const [newStep, setNewStep] = useState({ name: '', objective: '' })
  const [editIndex, setEditIndex] = useState(null)
  const [sendAlert, setSendAlert] = useState('')

  useEffect(() => {
    getAccountPipelines()
  }, [])

  useEffect(() => {
    if (sendAlert === 'success' || sendAlert === 'error') {
      setTimeout(() => {
        setSendAlert('')
      }, 3000)
    }
  }, [sendAlert])

  useEffect(() => {
    console.log('FormData Pipeline:', formData)
  }, [accountId, pipeline_id, formData])

  async function getAccountPipelines() {
    if (!accountId) return

    try {
      const response = await axios.get(`/pipelines?account_id=${accountId}`, { headers: authHeader() })

      setFormData((prevFormData) => ({
        ...prevFormData,
        order: response.data.data.length + 1
      }))
    } catch (error) {
      console.log('Erro ao carregar pipelines da conta:', error)
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const handleStepChange = (event) => {
    const { name, value } = event.target
    setNewStep((prevStep) => ({
      ...prevStep,
      [name]: value
    }))
  }

  const addStep = () => {
    setPipelineSteps((prevSteps) => [...prevSteps, { ...newStep, order: prevSteps.length + 1 }])
    setNewStep({ name: '', objective: '' }) // Limpa os campos após adicionar
  }

  const removeStep = (index) => {
    setPipelineSteps((prevSteps) => prevSteps.filter((_, i) => i !== index))
  }

  const handleEditStep = (index) => {
    setEditIndex(index)
    setNewStep(pipelineSteps[index])
  }

  const saveEditStep = () => {
    setPipelineSteps((prevSteps) => prevSteps.map((step, i) => (i === editIndex ? newStep : step)))
    setEditIndex(null)
    setNewStep({ name: '', objective: '' }) // Limpa os campos após salvar
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      let response

      response = await axios.post('/pipelines', formData, { headers: authHeader() })
      console.log('Post response: ', response.data)
      const newPipelineId = response.data.id

      // Enviar as etapas do funil
      for (let index = 0; index < pipelineSteps.length; index++) {
        const step = pipelineSteps[index]
        await axios.post(
          '/steps',
          {
            ...step,
            account_id: accountId,
            pipeline_id: newPipelineId,
            order: index + 1,
            goal: ''
          },
          { headers: authHeader() }
        )
      }

      setSendAlert('success')
      console.log('Dados enviados com sucesso!', response.data)
    } catch (error) {
      setSendAlert('error')
      console.error('Ocorreu um erro ao enviar os dados:', error.response.data)
    }
  }

  return (
    <Card>
      <Text fontSize="lg" fontWeight={'800'} mb="20px">
        Adicione um novo funil
      </Text>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <SimpleGrid columns={{ base: '1', md: '2.5' }} gap="0px 20px">
            <Flex direction="column">
              <Input
                value={formData.name}
                name="name"
                onChange={handleChange}
                isRequired={true}
                variant="auth"
                fontSize="sm"
                placeholder="Nome do funil"
                mb="24px"
                fontWeight="500"
                size="md"
              />
            </Flex>
          </SimpleGrid>
          <FormLabel fontSize="md" fontWeight="800" color={textColor} mb="8px">
            Etapas do funil
          </FormLabel>

          <Flex flexDirection={'row'}>
            {pipelineSteps.map((step, index) => (
              <Flex
                key={index}
                direction="column"
                width={'170px'}
                p="20px"
                boxShadow={'md'}
                borderRadius={'20px'}
                mr="20px"
                mb="20px"
              >
                {editIndex === index ? (
                  <>
                    <Input
                      value={newStep.name}
                      name="name"
                      onChange={handleStepChange}
                      variant="auth"
                      fontSize="sm"
                      placeholder="Nome da etapa"
                      mb="8px"
                      size="md"
                    />
                    <Textarea
                      value={newStep.objective}
                      name="objective"
                      onChange={handleStepChange}
                      variant="filled"
                      fontSize="sm"
                      placeholder="Objetivo da etapa"
                      style={{ border: '1px solid #e0e5f2' }}
                      borderRadius={'20px'}
                      size="md"
                    />
                    <Button onClick={saveEditStep} mt="10px" colorScheme="green">
                      Salvar
                    </Button>
                  </>
                ) : (
                  <>
                    <Text fontWeight={'800'}>{step.name}</Text>
                    <Text>{step.objective}</Text>
                    <Flex mt="10px" justifyContent="space-between">
                      <IconButton
                        aria-label="Editar etapa"
                        icon={<EditIcon />}
                        onClick={() => handleEditStep(index)}
                        size="sm"
                      />
                      <IconButton
                        aria-label="Remover etapa"
                        icon={<DeleteIcon />}
                        onClick={() => removeStep(index)}
                        size="sm"
                        colorScheme="red"
                      />
                    </Flex>
                  </>
                )}
              </Flex>
            ))}
            <Flex
              width={'270px'}
              flexDirection="column"
              p="20px"
              boxShadow={'md'}
              borderRadius={'20px'}
              mr="20px"
              mb="20px"
            >
              <Input
                value={newStep.name}
                name="name"
                onChange={handleStepChange}
                variant="auth"
                fontSize="sm"
                placeholder="Nova etapa"
                mb="8px"
                size="md"
              />
              <Textarea
                value={newStep.objective}
                name="objective"
                onChange={handleStepChange}
                variant="filled"
                fontSize="sm"
                placeholder="Objetivo da etapa"
                style={{ border: '1px solid #e0e5f2' }}
                borderRadius={'20px'}
                size="md"
              />

              <Button
                onClick={addStep}
                mt="10px"
                colorScheme="blue"
                background={'#49BFFF'}
                borderRadius={'50px'}
              >
                Adicionar etapa
              </Button>
            </Flex>
          </Flex>

          <Button
            type="submit"
            fontSize="sm"
            background="#49BFFF"
            color="white"
            fontWeight="800"
            w="100px"
            h="50"
            mb="24px"
            borderRadius="50px"
          >
            Salvar
          </Button>
          {sendAlert === 'success' && (
            <Alert status="success">
              <AlertIcon />
              <AlertTitle>Sucesso!</AlertTitle>
              <AlertDescription>Dados enviados com sucesso!</AlertDescription>
            </Alert>
          )}
        </FormControl>
      </form>
    </Card>
  )
}
