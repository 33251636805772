// Chakra imports
import { Avatar, Box, Icon, Link, SimpleGrid, Text, useColorModeValue, Flex } from '@chakra-ui/react'

// Custom components
// import MiniCalendar from 'components/calendar/MiniCalendar'
import QuickCalendar from './components/QuickCalendar'
import Tasks from './components/Tasks'
import MiniStatistics from 'components/card/MiniStatistics'
import IconBox from 'components/icons/IconBox'
import React, { useState, useEffect } from 'react'
import { MdBarChart } from 'react-icons/md'
import { logout, getUserAuthentication, getUserAuthenticationRole, getUserAccountId } from 'hooks/authService'
import ActivityItem from './components/ActivityItem'
import TelemedicineRoom from './components/TelemedicineRoom'
import authHeader from 'hooks/authHeader'
import NewAccountForm from '../../admin/formAccount'
import axios from 'api/axios'
import Dashboard from 'layouts/admin'
import ApproachsBar from './components/ApproachsBar'
import RegistroLigacoes from '../calls/'
import ClientsDash from './components/ClientsDash'
import { Redirect } from 'react-router-dom'
import Card from 'components/card/Card'
import { DailyPills } from './components/DailyPills'
import { SalesInteligence } from './components/salesInteligence'
import Suport from './components/Suport'
import PipelineDash from './components/PIpelineDash'
import LastActivities from '../calendarCare/components/lastActivities'

export default function AdminDefault() {
  const userData = getUserAuthentication()
  const [accountData, setAccountData] = useState({})
  const [totalActivities, setTotalActivities] = useState()
  const [accountActivities, setAccountActivities] = useState()

  const accountId = getUserAccountId()

  useEffect(() => {
    getUserAccount()
    getAccountActivities()
  }, [])

  useEffect(() => {
    // console.log('totalActivities: ', totalActivities)
    // console.log('Conta autenticada:', accountData)
    console.log('accountActivities:', accountActivities)
  }, [accountData, totalActivities, accountActivities])

  async function getUserAccount() {
    try {
      const response = await axios.get(`/accounts/${accountId}`, { headers: authHeader() })
      setAccountData(response.data)
    } catch (error) {
      console.error('Crie uma nova conta.', error)
    }
  }

  async function getAccountActivities() {
    if (!accountId) return

    const response = await axios.get(`/activities?account_id=${accountId}`, {
      query: {
        status: { $ne: 'done' },
        $sort: { start_date: 1 },
        $limit: 10000
      },
      headers: authHeader()
    })
    setTotalActivities(response.data.total)
    setAccountActivities(response.data.data)

    // console.log('AccountActivities:', response)
  }

  // const [aproachLists, setAproachList] = useState()

  // async function getAccountActivities(account_id) {
  //   try {
  //     const {
  //       data: { data }
  //     } = await axios.get(`/activities?account_id=${account_id}`, { headers: authHeader() })
  //     console.log('approachList response', data.data)
  //     if (data) {
  //       const activitiesFiltered = filterActivitiesByTypeAndDate(data)
  //       setAproachList(activitiesFiltered)
  //     }
  //   } catch (err) {
  //     console.log('Erro ao carregar as atividades', err)
  //   }
  // }

  // function filterActivitiesByTypeAndDate(activities) {
  //   const now = new Date()

  //   const filteredActivities = activities.filter((activity) => {
  //     const typesAllowed = ['call', 'message', 'email']
  //     const updatedAt = new Date(activity.updated_at)

  //     return typesAllowed.includes(activity.type) && updatedAt < now
  //   })

  //   const sortedActivities = filteredActivities.sort(
  //     (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
  //   )

  //   return sortedActivities
  // }

  useEffect(() => {
    accountId && getAccountActivities(accountId)
  }, [])

  return (
    <Box pt={{ base: '50px', md: '80px', xl: '80px' }}>
      {accountData && accountData.status === 'active' && (
        <SimpleGrid rows={2} gap="20px">
          <SimpleGrid columns={{ sm: 1, xl: 2 }} gap="20px">
            <Flex flexDirection={'column'} gap="20px">
              <ClientsDash />
              {/* <PipelineDash /> */}
              <RegistroLigacoes type="module" />
              {/* <LastActivities activities={accountActivities} /> */}
              <ApproachsBar total={totalActivities} />
            </Flex>
            <SimpleGrid columns={2} gap="20px">
              <Flex flexDirection={'column'} gap="20px">
                <QuickCalendar
                  h="100%"
                  minW="100%"
                  selectRange={false}
                  activities={accountActivities}
                  accountData={accountData}
                />
              </Flex>
              <Flex flexDirection={'column'} gap="20px">
                <DailyPills />
                <SalesInteligence accountData={accountData} />
                <Suport accountData={accountData} />
              </Flex>
              <Tasks accountId={accountId} />
            </SimpleGrid>
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap="20px">
            <Text>Suporte</Text>
            <Text>Sugestões</Text>
            <Text>Anúncios representantes</Text>
            <Text>Comunicação Otten Sales: Mídias sociais, contrate um atendente</Text>
          </SimpleGrid>
        </SimpleGrid>
      )}
    </Box>
  )
}
