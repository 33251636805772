import { Flex } from '@chakra-ui/react'

export default function PipelineDash() {
  const SalesFunnel = ({ steps }) => {
    // Verifica se as etapas são fornecidas corretamente
    if (!steps || steps.length < 2) {
      return <p>Informe pelo menos duas etapas com percentuais válidos.</p>
    }

    const funnelWidth = 300 // Largura inicial do funil
    const funnelHeight = 400 // Altura total do funil
    const stepHeight = funnelHeight / steps.length // Altura de cada etapa

    return (
      <svg width={funnelWidth} height={funnelHeight} style={{ display: 'block', margin: '0 auto' }}>
        {steps.map((step, index) => {
          // Calcula as larguras de cima e de baixo de cada etapa
          const topWidth = funnelWidth * (index === 0 ? 1 : steps[index - 1].percent / 100)
          const bottomWidth = funnelWidth * (step.percent / 100)

          // Calcula as coordenadas dos vértices do trapézio
          const topLeft = (funnelWidth - topWidth) / 2
          const bottomLeft = (funnelWidth - bottomWidth) / 2
          const yTop = index * stepHeight
          const yBottom = yTop + stepHeight

          return (
            <polygon
              key={index}
              points={`
                ${topLeft},${yTop} 
                ${topLeft + topWidth},${yTop} 
                ${bottomLeft + bottomWidth},${yBottom} 
                ${bottomLeft},${yBottom}
              `}
              fill={step.color || '#48bfff'}
              stroke="#fff"
              strokeWidth="1"
            />
          )
        })}
        {steps.map((step, index) => (
          <text
            key={`text-${index}`}
            x={funnelWidth / 2}
            y={(index + 0.5) * stepHeight}
            textAnchor="middle"
            fill="#fff"
            fontSize="14"
            fontFamily="Arial"
          >
            {step.name} ({step.percent}%)
          </text>
        ))}
      </svg>
    )
  }

  const HorizontalSalesFunnel = ({ steps }) => {
    if (!steps || steps.length < 2) {
      return <p>Informe pelo menos duas etapas com percentuais válidos.</p>
    }

    const funnelWidth = 600 // Comprimento total do funil
    const funnelHeight = 100 // Altura inicial do funil
    const stepWidth = funnelWidth / steps.length // Largura de cada etapa

    return (
      <svg width={funnelWidth} height={funnelHeight} style={{ display: 'block', margin: '0 auto' }}>
        {steps.map((step, index) => {
          // Calcula as alturas de cima e de baixo de cada etapa
          const topHeight = funnelHeight * (index === 0 ? 1 : steps[index - 1].percent / 100)
          const bottomHeight = funnelHeight * (step.percent / 100)

          // Calcula as coordenadas dos vértices do trapézio
          const xLeft = index * stepWidth
          const xRight = xLeft + stepWidth
          const yTop = (funnelHeight - topHeight) / 2
          const yBottom = (funnelHeight - bottomHeight) / 2

          return (
            <polygon
              key={index}
              points={`
                ${xLeft},${yTop}
                ${xRight},${yBottom}
                ${xRight},${funnelHeight - yBottom}
                ${xLeft},${funnelHeight - yTop}
              `}
              fill={step.color || '#48bfff'}
              stroke="#fffi"
              strokeWidth="1"
            />
          )
        })}
        {steps.map((step, index) => (
          <text
            key={`text-${index}`}
            x={(index + 0.5) * stepWidth}
            y={funnelHeight / 2 + 3}
            textAnchor="middle"
            fill="#fff"
            fontSize="0.8rem"
            // fontFamily="Arial"
          >
            {step.name}
          </text>
        ))}
      </svg>
    )
  }

  // Exemplo de uso:
  const steps = [
    { name: 'Contatar', percent: 100, color: '#162961' },
    { name: 'Abordando', percent: 70, color: '#273c7b' },
    { name: 'Contato', percent: 55, color: '#3673a1' },
    { name: 'Qualificando', percent: 27, color: '#48bfff' },
    { name: 'Apresentação', percent: 22, color: '#48bfff' },
    { name: 'Proposta', percent: 20, color: '#48bfff' },
    { name: 'Negociação', percent: 18, color: '#48bfff' }
  ]

  return (
    <Flex px="10px" pb="10px">
      <HorizontalSalesFunnel steps={steps} />
    </Flex>
  )
}
