/* eslint-disable */
import { Button, Flex, Icon, IconButton, Link, Select, Tag, Text } from '@chakra-ui/react'
import axios from 'api/axios'
import {
  FaEnvelope,
  FaPhone,
  FaSearch,
  FaTasks,
  FaComments,
  FaHandshake,
  FaWalking,
  FaFlag
} from 'react-icons/fa'
import { MdPersonAdd } from 'react-icons/md'
import { getUserAuthenticationRole } from 'hooks/authService'
import pipelineStatus from 'components/setPipelineStatusName'
import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { InputText } from 'primereact/inputtext'
import { getUserAccountId } from 'hooks/authService'
import authHeader from 'hooks/authHeader'
import { SelectButton } from 'primereact/selectbutton'

export default function ActivityTable({ dataTable, module, selectedActivityAction }) {
  const [activitiesList, setActivitiesList] = useState(dataTable)
  const [typeFilter, setTypeFilter] = useState('')
  const userRole = getUserAuthenticationRole()

  const accountId = getUserAccountId()
  // console.log('accountId', accountId)

  const [selectedActivity, setSelectedActivity] = useState({})

  const [statusFilter, setStatusFilter] = useState('')
  const [stageFilter, setStageFilter] = useState('')
  // const [typeFilter, setTypeFilter] = useState('')

  useEffect(() => {
    console.log('selectedActivity', selectedActivity)
    selectedActivityAction(selectedActivity)
  }, [selectedActivity])

  const statusList = [
    {
      name: 'Fazer',
      value: 'todo'
    },
    {
      name: 'Fazendo',
      value: 'doing'
    },
    {
      name: 'Impedida',
      value: 'blocked'
    },
    {
      name: 'Adiada',
      value: 'postponed'
    },
    {
      name: 'Cancelada',
      value: 'canceled'
    },
    {
      name: 'Realizada',
      value: 'done'
    }
  ]

  const taskTypes = [
    {
      name: 'Ligações',
      value: 'call'
    },
    {
      name: 'Mensagens',
      value: 'message'
    },
    {
      name: 'Emails',
      value: 'email'
    },
    {
      name: 'Tarefas',
      value: 'task'
    },
    {
      name: 'Reuniões',
      value: 'meeting'
    },
    {
      name: 'Visitas',
      value: 'visit'
    }
  ]

  useEffect(() => {
    setActivitiesList(dataTable)
  }, [dataTable])

  const [globalFilterValue, setGlobalFilterValue] = useState('')

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'deal.step.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'deal.contact.first_name': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'deal.contact.last_name': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'deal.contact.position': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'deal.company.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
    description: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })

  const onGlobalFilterChange = (e) => {
    const value = e.target.value.toLowerCase()
    let _filters = { ...filters }

    _filters['global'].value = value
    _filters['deal.step.name'].value = value
    _filters['deal.contact.first_name'].value = value
    _filters['deal.contact.last_name'].value = value
    _filters['deal.contact.position'].value = value
    _filters['deal.company.name'].value = value
    _filters['description'].value = value
    _filters['status'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  // const [filters, setFilters] = useState({
  //   global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  //   first_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  //   'address.municipio': { value: null, matchMode: FilterMatchMode.CONTAINS },
  //   status: { value: null, matchMode: FilterMatchMode.CONTAINS },
  //   'address.uf': { value: null, matchMode: FilterMatchMode.CONTAINS }
  // })

  // const onGlobalFilterChange = (e) => {
  //   const value = e.target.value
  //   let _filters = { ...filters }

  //   _filters['global'].value = value

  //   setFilters(_filters)
  //   setGlobalFilterValue(value)
  // }

  // const onGlobalFilterChange = (e) => {
  //   const value = e.target.value.toLowerCase()
  //   let updatedFilters = { ...filters }

  //   // Atualiza o filtro para cada coluna relevante
  //   Object.keys(updatedFilters).forEach((key) => {
  //     if (key !== 'global') {
  //       updatedFilters[key].value = value // Define o valor do filtro
  //     }
  //   })

  //   updatedFilters.global.value = value // Define também o valor do filtro global, se necessário
  //   setFilters(updatedFilters)
  //   setGlobalFilterValue(value)
  // }

  function RenderActivityIcon({ value }) {
    const activity = activityMapping[value]

    if (!activity) {
      return <span>Ops..</span>
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
        <span style={{ marginRight: '8px' }}>{activity.icon}</span>
        {/* <span>{activity.name}</span> */}
      </div>
    )
  }

  const activityMapping = {
    call: { icon: <FaPhone color="gray" />, name: 'Ligação' },
    task: { icon: <FaTasks color="gray" />, name: 'Tarefa' },
    email: { icon: <FaEnvelope color="gray" />, name: 'Email' },
    message: { icon: <FaComments color="gray" />, name: 'Mensagem' },
    meeting: { icon: <FaHandshake color="gray" />, name: 'Reunião' },
    visit: { icon: <FaWalking color="gray" />, name: 'Visita' }
  }

  const prioritiesList = [
    {
      value: 'Baixa prioridade',
      flag: 'low',
      color: '#49BFFF'
    },
    {
      value: 'Prioridade normal',
      flag: 'normal',
      color: '#e9e9e9'
    },
    {
      value: 'Alta prioridade',
      flag: 'high',
      color: '#F6E05E'
    },
    {
      value: 'Máxima prioridade ',
      flag: 'top',
      color: '#E53E3E'
    }
  ]

  function getColorByFlag(flag) {
    const priority = prioritiesList.find((item) => item.flag === flag)
    return priority ? priority.color : 'transparent'
  }

  const renderHeader = () => {
    return (
      <Flex justifyContent={'space-between'} gap={'10px'}>
        <Text fontSize={'1.2rem'} fontWeight={'800'} color={'#1b2559'}>
          Próximas Atividades
        </Text>

        <Flex>
          <Select
            placeholder="Período"
            variant="auth"
            value={typeFilter}
            onChange={(e) => setTypeFilter(e.target.value)}
            borderRadius="20px"
          >
            <option key="" value="">
              Todas as atividades
            </option>
            <option key="late" value="late">
              Atrasados
            </option>
            <option key="today" value="today">
              Hoje
            </option>
            <option key="week" value="week">
              Na semana
            </option>
            <option key="month" value="month">
              No mês
            </option>
          </Select>
        </Flex>
        <Flex>
          <Select
            placeholder="Atividade"
            variant="auth"
            value={typeFilter}
            onChange={(e) => setTypeFilter(e.target.value)}
            borderRadius="20px"
          >
            <option key="" value="">
              Todas as atividades
            </option>
            {taskTypes.map((task) => (
              <option key={task.value} value={task.value}>
                {task.name}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex gap="10px">
          <Select
            placeholder="Status"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            borderRadius="20px"
          >
            <option key="" value="">
              Todos os Status
            </option>
            {statusList.map((status) => (
              <option key={status.value} value={status.value}>
                {status.name}
              </option>
            ))}
          </Select>

          <Select
            placeholder="Etapa"
            value={stageFilter}
            onChange={(e) => setStageFilter(e.target.value)}
            borderRadius="20px"
          >
            <option key="" value="">
              Todas as Etapas
            </option>
            {dataTable &&
              [...new Set(dataTable.map((activity) => activity.deal.step.name))].map((stage) => (
                <option key={stage} value={stage}>
                  {stage}
                </option>
              ))}
          </Select>
        </Flex>
        <Flex alignItems={'center'} background={'#f4f7fe'} borderRadius={'20px'}>
          <div className="p-inputgroup flex-1">
            <span
              className="p-inputgroup-addon"
              style={{
                background: 'transparent'
              }}
            >
              <FaSearch />
            </span>
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Buscar"
              v-model="value1"
              style={{
                padding: ' 0px 10px',
                width: '100%',
                background: 'transparent',
                fontSize: '14px'
              }}
              className="outline-none"
            />
          </div>
        </Flex>
      </Flex>
    )
  }

  const header = renderHeader()

  const flag = (item) => {
    return (
      <Flex>
        <IconButton
          colorScheme={'white'}
          color={getColorByFlag(item.flag)}
          // variant="outline"
          aria-label="Priority"
          borderRadius={'50px'}
          size="sm"
          p="1px"
          w="20px"
          icon={<Icon as={FaFlag} />}
        />
      </Flex>
    )
  }

  const pipelineStep = (item) => {
    return (
      <Flex color="#1b2559">
        {/* <Tag
          colorScheme={'blue'}
          size="sm"
          borderRadius={'20px'}
          fontSize={'12px'}
          fontWeight={'400'}
          mr="5px"
        >
          {item.deal.step.pipeline.name}
        </Tag> */}
        <Tag colorScheme={'teal'} size="sm" borderRadius={'20px'} fontSize={'12px'} fontWeight={'400'}>
          {item.deal.step.name}
        </Tag>
      </Flex>
    )
  }

  const profile = (item) => {
    return (
      <Flex alignItems={'center'}>
        <Flex>
          <RenderActivityIcon value={item.type} />
        </Flex>
        <Flex color="#1b2559" flexDirection={'column'}>
          <Text fontSize={'14px'} fontWeight={'600'}>
            {`${item.deal.contact.first_name} ${item.deal.contact.last_name}`}
          </Text>
          <Text fontSize={'12px'} fontWeight={'400'} fontStyle={'italic'}>
            {`${item.deal.contact.position}`}
          </Text>
          <Text fontSize={'14px'} fontWeight={'400'}>
            {`${item.deal.company.name}`}
          </Text>
        </Flex>
      </Flex>
    )
  }

  const description = (item) => {
    return (
      <Flex color="#1b2559">
        <Text noOfLines={3} maxW="100%" fontSize={'14px'}>
          {item.description}
        </Text>
      </Flex>
    )
  }

  const startDate = (item) => {
    return (
      <Flex color="#1b2559">
        {item.start_date && (
          <Tag
            colorScheme={'blackAlpha'}
            size="sm"
            borderRadius={'20px'}
            fontSize={'12px'}
            fontWeight={'400'}
            mr="5px"
            px="12px"
          >
            {convertTimestampToDateAndTime(item.start_date)}
          </Tag>
        )}
      </Flex>
    )
  }

  const status = (item) => {
    return (
      <Flex color="#1b2559">
        <Tag
          colorScheme={'blackAlpha'}
          size="sm"
          borderRadius={'20px'}
          fontSize={'12px'}
          fontWeight={'400'}
          mr="5px"
          px="12px"
        >
          {item.status}
        </Tag>
      </Flex>
    )
  }

  function convertTimestampToDateAndTime(timestamp) {
    // Remove os três últimos dígitos do timestamp
    const date = new Date(Math.floor(timestamp / 1000) * 1000)
    const day = date.getDate().toString().padStart(2, '0')
    const month =
      date.toLocaleString('default', { month: 'short' }).charAt(0).toUpperCase() +
      date.toLocaleString('default', { month: 'short' }).slice(1)
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    return `${day}/${month} • ${hours}h${minutes}`
  }

  useEffect(() => {
    const filteredActivities = dataTable.filter((activity) => {
      const matchesStatus = !statusFilter || activity.status === statusFilter
      const matchesStage = !stageFilter || activity.deal.step.name === stageFilter
      const matchesType = !typeFilter || activity.type === typeFilter

      return matchesStatus && matchesStage && matchesType
    })

    setActivitiesList(filteredActivities)
  }, [statusFilter, stageFilter, typeFilter, dataTable])

  return (
    <Flex gap={'20px'} flexDirection={'column'} mt={module ? '20px' : '80px'}>
      <Flex bg={'white'} p={'20px'} borderRadius={'20px'} width={'fit-content'}>
        <DataTable
          value={activitiesList && activitiesList}
          header={header}
          filters={filters}
          paginator
          selectionMode="single"
          selection={selectedActivity}
          onSelectionChange={(e) => setSelectedActivity(e.value)}
          rows={8}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey="id"
          globalFilterFields={[
            'deal.step.name',
            'deal.contact.first_name',
            'deal.contact.last_name',
            'deal.contact.position',
            'deal.company.name',
            'description',
            'status'
          ]}
          tableStyle={{ width: '100vh' }}
          responsiveLayout="scroll"
          pt={{
            header: {
              style: {
                background: '#fff'
              }
            }
          }}
        >
          <Column
            header=" "
            body={flag}
            style={{ minWidth: '50px' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0',
                  fontSize: '0.85rem'
                }
              }
            }}
          ></Column>
          <Column
            header="Etapa"
            body={pipelineStep}
            style={{ minWidth: '150px' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0',
                  fontSize: '0.85rem'
                }
              }
            }}
          ></Column>
          <Column
            header="Contato"
            body={profile}
            style={{ minWidth: '300px' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0',
                  fontSize: '0.85rem'
                }
              }
            }}
          ></Column>

          <Column
            header="Descrição"
            body={description}
            style={{ minWidth: '330px' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0',
                  fontSize: '0.85rem'
                }
              }
            }}
          ></Column>
          <Column
            header="Data"
            body={startDate}
            style={{ minWidth: '150px' }}
            sortable
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0',
                  fontSize: '0.85rem'
                }
              }
            }}
          ></Column>
          <Column
            header="Status"
            body={status}
            style={{ minWidth: '150px' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0',
                  fontSize: '0.85rem'
                }
              }
            }}
          ></Column>
        </DataTable>
      </Flex>
    </Flex>
  )
}
