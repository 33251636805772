import React, { useEffect, useMemo, useState } from 'react'
import {
  Avatar,
  Center,
  Flex,
  Link,
  Icon,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button
} from '@chakra-ui/react'
import { MdCheck, MdClose, MdImportExport, MdPersonAdd, MdAdd } from 'react-icons/md'
import { app } from 'api/feathers'
import { getUserAccountId } from 'hooks/authService'

// Custom components
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import authHeader from 'hooks/authHeader'
import formatDateDDMMYYYY from 'hooks/formatDateDDMMYYYY'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FaSearch } from 'react-icons/fa'
import { InputText } from 'primereact/inputtext'
const account_id = getUserAccountId()

export default function CampaignsTable() {
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    async function buscarCampanhas() {
      try {
        const { data } = await app.service('campaigns').find({
          query: {
            account_id: account_id
          },
          headers: authHeader()
        })
        setTableData(data)
        console.log(data)
      } catch (error) {
        console.error('Erro ao buscar campanhas:', error)
      }
    }

    buscarCampanhas()
  }, [])

  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const iconColor = useColorModeValue('secondaryGray.500', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')

  const renderHeader = () => {
    return (
      <Flex justifyContent={'space-between'} gap={'10px'}>
        <Text fontSize={'24px'} fontWeight={'600'} color={'#1b2559'} fontFamily={'DM Sans'}>
          Processos Comerciais
        </Text>

        <Flex gap={'10px'} alignItems={'center'}>
          <Link href="/admin/new-campaign">
            <Button
              leftIcon={<MdAdd fontWeight={'500'} size={'18px'} />}
              colorScheme="gray"
              color="gray.400"
              variant="solid"
              fontWeight={'500'}
              borderRadius={'50px'}
              size="md"
            >
              Novo
            </Button>
          </Link>
        </Flex>
      </Flex>
    )
  }

  const header = renderHeader()

  const processo = (item) => {
    return (
      <Link href={`/admin/campaign/${item.id}`}>
        <Text color={textColor} fontSize="sm" fontWeight="600" fontFamily={'DM Sans'}>
          {item.subject}
        </Text>
        <Text fontFamily={'DM Sans'}>{item.description}</Text>
      </Link>
    )
  }

  const pipeline = (item) => {
    return (
      <Text color={textColor} fontSize="sm" fontWeight="400">
        {item.pipeline.name}
      </Text>
    )
  }

  const startDate = (item) => {
    return (
      <Text color={textColor} fontSize="sm" fontWeight="400">
        {formatDateDDMMYYYY(item.start_date)}
      </Text>
    )
  }

  const endDate = (item) => {
    return (
      <Text color={textColor} fontSize="sm" fontWeight="400">
        {formatDateDDMMYYYY(item.end_date)}
      </Text>
    )
  }

  const status = (item) => {
    return (
      <Text color={textColor} fontSize="sm" fontWeight="400">
        {item.status === 'active' ? (
          <Icon as={MdCheck} color="green" w="20px" h="20px" />
        ) : (
          <Icon as={MdClose} color="red" w="20px" h="20px" />
        )}
      </Text>
    )
  }

  return (
    <Flex gap={'20px'} flexDirection={'column'}>
      <Flex bg={'white'} p={'20px'} borderRadius={'20px'} width={'fit-content'}>
        <DataTable
          value={tableData}
          header={header}
          // filters={filters}
          paginator
          rows={8}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey="id"
          // globalFilterFields={['name', 'contact', 'address', 'address.municipio', 'address.uf']}
          tableStyle={{ width: '100vh' }}
          pt={{
            header: {
              style: {
                background: '#fff'
              }
            }
          }}
        >
          <Column
            field="process"
            header="Processo"
            body={processo}
            style={{ minWidth: '400px', fontFamily: 'DM Sans' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0',
                  paddingBottom: '3px'
                }
              }
            }}
          ></Column>
          <Column
            field="pipeline"
            header="Pipeline"
            body={pipeline}
            style={{ minWidth: '150px', fontFamily: 'DM Sans' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0',
                  paddingBottom: '3px'
                }
              }
            }}
          ></Column>
          <Column
            field="address"
            header="Inicio"
            body={startDate}
            style={{ minWidth: '150px', fontFamily: 'DM Sans' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0',
                  paddingBottom: '3px'
                }
              }
            }}
          ></Column>
          <Column
            field="address"
            header="Fim"
            body={endDate}
            style={{ minWidth: '150px', fontFamily: 'DM Sans' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0',
                  paddingBottom: '3px'
                }
              }
            }}
          ></Column>
          <Column
            field="pipeline"
            header="Status"
            body={status}
            style={{ minWidth: '150px', fontFamily: 'DM Sans' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0',
                  paddingBottom: '3px'
                }
              }
            }}
          ></Column>
        </DataTable>
      </Flex>
    </Flex>
  )
}
