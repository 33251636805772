import React, { useRef, useState } from 'react'
import {
  AlertDescription,
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  FormControl,
  FormHelperText,
  Input,
  Button,
  FormLabel,
  useColorModeValue,
  SimpleGrid,
  Text
} from '@chakra-ui/react'
import authHeader from 'hooks/authHeader'
import { logout, getUserAuthentication } from 'hooks/authService'
import Card from 'components/card/Card'
import axios from 'api/axios'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { loginLocal } from 'hooks/authService'
import { Toast } from 'primereact/toast'
import { checkLoginData } from 'hooks/authService'

function AtualizarSenha() {
  const { userId } = useParams()
  const [senhaAtual, setSenhaAtual] = useState('')
  const [novaSenha, setNovaSenha] = useState('')
  const [confirmarSenha, setConfirmarSenha] = useState('')
  const [erro, setErro] = useState('')
  const [sendAlert, setSendAlert] = useState('')

  const textColor = useColorModeValue('navy.700', 'white')

  const userData = getUserAuthentication()
  console.log('userData:', userData)

  const handleSenhaAtualChange = (event) => {
    setSenhaAtual(event.target.value)
  }

  const handleNovaSenhaChange = (event) => {
    setNovaSenha(event.target.value)
  }

  const handleConfirmarSenhaChange = (event) => {
    setConfirmarSenha(event.target.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    // Verificar se as novas senhas são idênticas
    if (novaSenha !== confirmarSenha) {
      setErro('As novas senhas não coincidem.')
      console.log('As novas senhas não coincidem.')
      return
    }

    // Verificar se a nova senha atende aos requisitos
    const senhaRegex = /^(?=.*[A-Z])(?=.*\d{2})(?=.*[!@#$%^&*]).{8,}$/
    if (!senhaRegex.test(novaSenha)) {
      setErro('A nova senha não atende aos requisitos.')
      console.log('A nova senha não atende aos requisitos.')
      return
    }

    // Se as validações passarem, envia 'senhaAtual' para API onde é comparada com a senha registrada,
    // se coincidirem, altera o password com o valor de 'novaSenha'.
    try {
      // console.log('authHeader: ', authHeader())

      const checkActualPassword = await checkLoginData(userData.user.email, senhaAtual)

      if (checkActualPassword.status !== 201)
        return toastError('A senha atual informada não coincide com a cadastrada.')

      const password = {
        password: novaSenha
      }

      console.log('checkActualPassword: ', checkActualPassword.status)
      const response = await axios.patch(`/users/${userData.user.id}`, password, { headers: authHeader() })
      console.log('Dados atualizados com sucesso!', response.data)
      toastSuccess(
        'Senha atualizada! Em 3s Você será redirecionado para a tela de login para entrar com a nova senha.'
      )
      // setSendAlert('success')
      setTimeout(() => logout(), 3000)
    } catch (error) {
      toastError('Ocorreu um erro ao atualizar a senha.')
      console.error('Ocorreu um erro ao enviar os dados:', error)
    }
    setErro('')
  }

  const toast = useRef(null)
  const toastSuccess = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message })
  }
  const toastError = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message
    })
  }

  return (
    <Box>
      <SimpleGrid mb="20px" columns={1} spacing={{ base: '20px', xl: '20px' }}>
        <Card>
          <Text
            style={{
              color: '#1a202c',
              fontSize: '22px',
              fontWeight: '700',
              lineHeight: '100%'
            }}
            mb={'20px'}
          >
            Altere sua senha
          </Text>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Senha atual
              </FormLabel>
              <Input
                type="password"
                value={senhaAtual}
                onChange={handleSenhaAtualChange}
                name="senhaAtual"
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Senha atual"
                mb="24px"
                fontWeight="500"
                size="md"
              />
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Nova senha
              </FormLabel>
              <Input
                type="password"
                value={novaSenha}
                onChange={handleNovaSenhaChange}
                name="novaSenha"
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Nova senha"
                fontWeight="500"
                size="md"
              />
              <FormHelperText mb="24px">
                Deve ter ao menos 8 caracteres, 2 números e 1 caracter especial.
              </FormHelperText>
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Confirmar nova senha
              </FormLabel>
              <Input
                type="password"
                value={confirmarSenha}
                onChange={handleConfirmarSenhaChange}
                name="confirmarSenha"
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Confirme a senha"
                mb="24px"
                fontWeight="500"
                size="md"
              />
              <Button type="submit" fontSize="sm" variant="brand" fontWeight="500" w="100%" h="50" mb="24px">
                Atualizar senha
              </Button>
              {erro && (
                <div>
                  <Alert status="error">
                    <AlertIcon />
                    <AlertTitle>Erro</AlertTitle>
                    <AlertDescription>{erro}</AlertDescription>
                  </Alert>
                </div>
              )}
              {sendAlert === 'success' && (
                <Alert status="success">
                  <AlertIcon />
                  <AlertTitle>Sucesso!</AlertTitle>
                  <AlertDescription>Dados enviados com sucesso!</AlertDescription>
                </Alert>
              )}
              {sendAlert === 'error' && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle>Erro!</AlertTitle>
                  <AlertDescription>Verifique os dados digitados e tente novamente.</AlertDescription>
                </Alert>
              )}
            </FormControl>
          </form>
        </Card>
      </SimpleGrid>
      <Toast position="bottom-center" ref={toast} />
    </Box>
  )
}

export default AtualizarSenha
