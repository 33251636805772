import { Button, Flex, FormControl, Grid, GridItem, Tag, Text } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { getUserAccountId } from 'hooks/authService'
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog'
import { Dropdown } from 'primereact/dropdown'
import { IconField } from 'primereact/iconfield'
import { InputIcon } from 'primereact/inputicon'
import { InputText } from 'primereact/inputtext'
import { SelectButton } from 'primereact/selectbutton'
import { Toast } from 'primereact/toast'
import { useEffect, useRef, useState } from 'react'
import { FaBuilding, FaUserTie } from 'react-icons/fa'
import { estadosUF } from 'contexts/dadosBrasileiros'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'

export default function UserAccountForm() {
  const accountId = getUserAccountId()
  const user_id = ''

  const toast = useRef(null)
  const toastSuccess = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message })
  }
  const toastError = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message
    })
  }

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    status: 'active',
    role: '',
    user_data: {
      phone1: '',
      main_account_id: accountId
    }
  })

  useEffect(() => {
    console.log('formData', formData)
  }, [formData])

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      await axios.post('/users', formData, { headers: authHeader() })
      toastSuccess('Usuário criado com sucesso!')
    } catch (error) {
      toastError('Usuário criado com sucesso!')
      console.log('Erro ao criar usuário', error)
    }
  }

  async function handleDeleteUser(e) {
    e.preventDefault()
  }

  function handleChange(e) {
    const { name, value } = e.target

    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const roleOptions = [
    {
      name: 'Vendedor',
      value: 'seller'
    },
    {
      name: 'Gestor',
      value: 'manager'
    }
  ]

  const confirmDealDelete = () => {
    confirmDialog({
      header: 'Excluir Oportunidade?',
      message: 'Tem certeza que deseja excluir esta oportunidade de negócio?',
      icon: 'pi pi-exclamation-triangle text-red-700',
      // acceptClassName: 'p-2 border-round-2xl ',
      rejectClassName: 'p-1 border-round-2xl text-blue-400 ',
      acceptLabel: 'Excluir',
      rejectLabel: 'Cancelar',
      accept: () => handleDeleteUser(), // Chama a função de exclusão se o usuário confirmar
      reject: () => console.log('Exclusão cancelada') // Ação se o usuário cancelar
    })
  }

  return (
    <Flex>
      <Card style={{ height: 'fit-content' }} p="20px" width={'100%'}>
        <Text fontSize={'22px'} fontWeight={'800'} mb={'10px'}>
          Novo Usuário
        </Text>
        <Grid templateColumns="repeat(6, 1fr)" gap={'20px'} width={'100%'}>
          <GridItem colSpan={3}>
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Nome
            </label>
            <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <FaUserTie />
                </span>
                <InputText
                  value={formData.first_name}
                  name="first_name"
                  onChange={handleChange}
                  placeholder="..."
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                  className="outline-none"
                />
              </div>
            </Flex>
          </GridItem>
          <GridItem colSpan={3}>
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Sobrenome
            </label>
            <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <FaUserTie />
                </span>
                <InputText
                  value={formData.last_name}
                  name="last_name"
                  onChange={handleChange}
                  placeholder="..."
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                  className="outline-none"
                />
              </div>
            </Flex>
          </GridItem>
          <GridItem colSpan={6}>
            <Flex gap="20px" mt="0px" flexDirection={'row'} maxWidth={'99%'}>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <FaBuilding />
                </span>
                <Dropdown
                  value={formData.role}
                  name="role"
                  onChange={handleChange}
                  options={roleOptions}
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Perfil de usuário"
                  // valueTemplate={selectedCountryTemplate}
                  // itemTemplate={countryOptionTemplate}
                  // className="w-full md:w-16rem text-base"
                  pt={{
                    root: { width: '100%' },
                    input: {
                      style: {
                        width: '100%',
                        background: '#fafafa',
                        fontSize: '14px'
                      }
                    },
                    trigger: {
                      style: {
                        background: '#fafafa',
                        fontSize: '14px'
                      }
                    },
                    item: {
                      style: {
                        fontSize: '14px',
                        padding: '8px 16px',
                        width: '100%',
                        background: '#fafafa'
                      }
                    },
                    panel: {
                      style: { marginTop: '5px' }
                    }
                  }}
                />
              </div>
            </Flex>
          </GridItem>
          <GridItem colSpan={3}>
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Email
            </label>
            <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <FaUserTie />
                </span>
                <InputText
                  value={formData.email}
                  name="email"
                  onChange={handleChange}
                  placeholder="..."
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                  className="outline-none"
                />
              </div>
            </Flex>
          </GridItem>
          <GridItem colSpan={3}>
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Senha
            </label>
            <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <FaUserTie />
                </span>
                <InputText
                  value={formData.password}
                  name="password"
                  onChange={handleChange}
                  placeholder="..."
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                  className="outline-none"
                />
              </div>
            </Flex>
          </GridItem>
        </Grid>

        <Flex flexDirection={'row-reverse'} style={{ justifyContent: 'flexEnd' }} mt="20px">
          <Button
            type="submit"
            onClick={handleSubmit}
            className="text-white text-center px-3 py-2"
            colorScheme="blue"
            background="#49BFFF"
            borderRadius="50px"
            fontSize="14px"
            fontWeight="600"
          >
            {user_id ? 'Atualizar' : 'Adicionar'}
          </Button>
        </Flex>
        <Toast position="bottom-center" ref={toast} />
        <ConfirmDialog
          defaultFocus={'reject'}
          pt={{
            acceptButton: {
              style: {
                background: '#cc0000',
                borderRadius: '20px',
                color: 'white',
                padding: '5px 10px',
                marginLeft: '20px'
              }
            }
            // rejectButton: {
            //   style: {
            //     padding: '5px 10px',
            //     // border: '1px solid #49BFFF',
            //     background: '#49BFFF',
            //     borderRadius: '20px',
            //     color: '#49BFFF'
            //   }
            // }
          }}
        />
      </Card>
      <Toast ref={toast} position="bottom-center" />
    </Flex>
  )
}
